import React, {useState, createRef} from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch } from "react-instantsearch-dom"
import SearchBox from "./search-box"
import SearchResult from "./search-result"
import useClickOutside from "./use-click-outside"

export default function Search({ indices }) {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  useClickOutside(rootRef, () => setFocus(false))

  return (
    <div className="site-header__search search" ref={rootRef}>
      <InstantSearch 
        indexName={indices[0].name}
        searchClient={searchClient}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <SearchBox className="search__form" onFocus={() => setFocus(true)} hasFocus={hasFocus} />
        <SearchResult
          show={query && query.length > 2 && hasFocus}
          indices={indices}
          className="search__result" 
        />
      </InstantSearch>
    </div>
  )
}