import React from 'react'

import Footer from '../footer/footer'
import {Helmet} from "react-helmet";

import '../../../styles/normalize.scss'
import '../../../styles/index.scss'

function Layout(props) {
  return (
    <>
      <Helmet>
        <link href="//fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap" rel="stylesheet" />
      </Helmet>
      {props.children}
      <Footer hasNoBorder={props.hasNoBorder}/>
    </>
  )
}

export default Layout