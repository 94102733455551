import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import SearchLogo from "../../../assets/svg/icons/magnifier.svg"

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className}>
      <SearchLogo />
      <input
        className="SearchInput"
        type="text"
        placeholder="Search..."
        aria-label="Search..."
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
    </form>
  )
)