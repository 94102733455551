import React from "react"
import clsx from "clsx";
import { Link } from "gatsby"

import Logo from "../../../assets/svg/cone-logo.svg"
import BazarLogo from "../../../assets/svg/bazar-logo.svg"
import GitHubLogo from "../../../assets/svg/icons/github.svg"
import TwitterLogo from "../../../assets/svg/icons/twitter.svg"
import styles from "./footer.module.scss"

function Footer(props) {
  return (
    <footer className={styles.footer}>
      <div className={styles.row}>
        <div className={clsx(styles.inner, {"has-no-border" : props.hasNoBorder})}>
          <div className={styles.top}>
            <div className={styles.information}>
              <Link className={styles.informationLogo} to="/" title="Bazar - Thoughtful Laravel eCommerce">
                <BazarLogo />
              </Link>
              <p className={styles.informationDescription}>Bazar is a powerful "headless" e-commerce system. Built on Laravel and Vue.</p>
              <div className={styles.socialIcons}>
                <a href="https://github.com/conedevelopment/bazar" className={styles.socialIcon} aria-label="GitHub">
                  <GitHubLogo />
                </a>
                <a href="https://twitter.com/conedevelopment" className={styles.socialIcon} aria-label="Twitter">
                  <TwitterLogo />
                </a>
              </div>
            </div>
            <div className={styles.navigations}>
              <div>
                <h3 className={styles.title}>Open Source</h3>
                <ul className={styles.menu}>
                  <li><Link to="/docs">Docs</Link></li>
                  <li><Link to="/news">News</Link></li>
                  <li><a href="https://github.com/conedevelopment/bazar/blob/master/CHANGELOG.md">Release notes</a></li>
                  <li><a href="https://github.com/conedevelopment/bazar/milestones">Milestones</a></li>
                </ul>
              </div>
              <div>
                <h3 className={styles.title}>Company</h3>
                <ul className={styles.menu}>
                  <li><a href="https://conedevelopment.com/about/">About</a></li>
                  <li><a href="https://conedevelopment.com/contact/">Contact</a></li>
                  <li><a href="https://pineco.de/">Pine</a></li>
                  <li><a href="https://sprucecss.com/">Spruce CSS</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <a className={styles.logo} href="https://conedevelopment.com" title="Cone Development">
              <span>Made by</span>
              <Logo />
            </a>
            <p className={styles.copyright}>© 2020-2021 Cone Development</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
