import React, {useState, useEffect} from "react"
import { Link } from "gatsby"
import clsx from 'clsx';
import Search from "../search/index"

import Logo from "../../../assets/svg/bazar-logo-beta.svg"
import Hamburger from "../../../assets/svg/icons/hamburger.svg"
import GitHubLogo from "../../../assets/svg/icons/github.svg"
import buttonStyles from "../button/button.module.scss"

const searchIndices = [{ name: `Docs`, title: `Docs` }, { name: `Posts`, title: `News` }]

function Header(props) {
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    if(menuVisible === true) {
      document.querySelector('.site-header__navigation').firstChild.focus();
    }
  });

  function handleMenuClick() {
    setMenuVisible(!menuVisible);
  }
  
  return (
    <>
      <a className="skip-link" href="#content">Skip to content</a>
      <header className={clsx({'site-header' : true, 'is-light' : props.isLight})}>
        <div className="site-header__row">
          <div className="site-header__inner">
            <div className="site-header__col">
              <Link className="site-header__logo" to="/" title="Bazar - Thoughtful Laravel eCommerce">
                <Logo/>
              </Link>
            </div>
            <div className="site-header__col">
              <div className="site-header__navigation" aria-controls="primary-menu" aria-expanded={menuVisible ? 'true' : 'false'}>
                <Search indices={searchIndices} />
                <nav>
                  <Link to="/docs">Docs</Link>
                  <Link to="/news">News</Link>
                </nav>
              </div>
              <a
                href="https://github.com/conedevelopment/bazar"
                className={buttonStyles.btn}
              >
                <GitHubLogo />
                GitHub
              </a>
              <button className="site-header__toggle" onClick={handleMenuClick} aria-controls="primary-menu" aria-expanded={menuVisible ? 'true' : 'false'} aria-label={menuVisible ? 'Close navigation' : 'Open navigation'}>
                <Hamburger />
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header