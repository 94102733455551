import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom"

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="search__hit-count">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : <div className="search__alert search__alert--warning">No result in this section.</div>
})

function PageHit({ hit }) {
  return(
    <div>
      <Link to={(hit.posttype === 'doc' ? `/docs/${hit.slug}` : `/news/${hit.slug}`)}>
        <h4>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h4>
      </Link>
      <Snippet attribute="excerpt" hit={hit} tagName="mark" />
    </div>
  )
}

function HitsInIndex({ index }) {
  return(
    <div className="search__section">
      <Index indexName={index.name}>
        <h3 className="search__title">{index.name}</h3>
        <HitCount />
        <Hits className="search__hits" hitComponent={PageHit} />
      </Index>
    </div>
  )
}

function SearchResult({ show, indices, className }) {
  return (
    <div 
      className={className}
      style={{display: show ? "block" : "none"}}
    >
      <div className="search__result-inside">
        {indices.map(index => (
          <HitsInIndex index={index} key={index.name} />
        ))}
      </div>
      <PoweredBy />
    </div>
  )
}

export default SearchResult